
import { dashWithTime } from "@/filters/utils";
import { UtilsMixin } from "@/mixins/utils.mixin";
import {
  TicketTypes,
  UpdateTicketStatus,
  UserTicket,
} from "@/store/modules/ticket/ticket.types";
import { Pagination } from "@/types/types";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataTableHeader, DataPagination } from "vuetify";
import { namespace } from "vuex-class";

const Ticket = namespace("Ticket");

@Component({
  filters: {
    dashWithTime,
  },
})
export default class ListTickets extends Mixins(UtilsMixin) {
  @Ticket.Mutation(TicketTypes.SET_UPDATE_TICKET_DIALOG)
  public setUpdateTicket!: (updateTicket: boolean) => void;

  @Ticket.Mutation(TicketTypes.SET_VIEW_TICKET_DIALOG)
  public setViewTicket!: (updateTicket: boolean) => void;

  @Ticket.Mutation(TicketTypes.SET_DELETE_TICKET_DIALOG)
  public setDeleteTicket!: (deleteTicket: boolean) => void;

  @Ticket.Mutation(TicketTypes.SET_CLOSE_TICKET_DIALOG)
  public setCloseTicket!: (closeTicket: boolean) => void;

  @Ticket.Mutation(TicketTypes.SET_TICKET_TICKET_DATA)
  public setUserTicketData!: (ticketTicket: UserTicket) => void;

  @Ticket.Action(TicketTypes.LOAD_TICKETS)
  public getTicketTickets!: (pagination: Pagination) => void;

  @Ticket.Action(TicketTypes.UPDATE_TICKET_STATUS)
  public updateTicketStatus!: (payload: UpdateTicketStatus) => Promise<void>;

  @Ticket.State(TicketTypes.TICKETS)
  public ticketTickets!: UserTicket[];

  @Ticket.State("pages")
  public pages!: number;

  @Ticket.State(TicketTypes.LOADING_TICKETS_STATE)
  public loadingTickets!: boolean;

  @Ticket.State(TicketTypes.TICKET_STATUSES)
  public ticketStatuses!: string[];

  @Prop() public search = "";

  public pagination: any = {};

  public headers: DataTableHeader[] = [
    {
      text: "#CASE NUMBER",
      sortable: false,
      value: "caseNumber",
      cellClass:
        "primary--text text--darken-2 font-weight-medium text-uppercase",
    },
    {
      text: "ISSUE",
      value: "issue",
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "ASSIGNED TO",
      value: "assignee",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "SLA(HOURS)",
      value: "sla",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "REPORT DATE",
      value: "reportingDate",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "PRIORITY",
      value: "priority",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "STATUS",
      value: "status",
      sortable: false,
      align: "center",
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  async changeTicketStatus(ticket: UserTicket, status: string): Promise<void> {
    if (status === "Closed") {
      this.closeTicket(ticket);
      return;
    }
    await this.updateTicketStatus({
      ticket,
      status,
    });
  }

  deleteTicket(item: UserTicket): void {
    this.setDeleteTicket(true);
    this.setUserTicketData(item);
  }

  editTicket(item: UserTicket): void {
    this.setUpdateTicket(true);
    this.setUserTicketData(item);
  }

  viewTicket(item: UserTicket): void {
    this.setViewTicket(true);
    this.setUserTicketData(item);
  }

  closeTicket(item: UserTicket): void {
    this.setCloseTicket(true);
    this.setUserTicketData(item);
  }

  loadItems({ page, itemsPerPage }: DataPagination): void {
    this.getTicketTickets({ page, limit: itemsPerPage });
  }

  mounted(): void {
    this.getTicketTickets({ page: 1, limit: 10 });
  }
}
